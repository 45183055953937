.App {
    min-height: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    display: flex;
    color: white;
    padding: 1.5rem;
    /*background: #282c34;*/
}

.App-header a.logo {
    color: #000;
    font-size: 1.85rem;
}

.App-header a {
    color: #000;
    font-size: 1.3rem;
    line-height: 1.2;
    margin-right: 2.5rem !important;
    font-weight: bold;
    letter-spacing: 1px;
}

.App-header a:last-child {
    margin-right: 0 !important;
}

.App-header a:hover {
    color: #1f113a;
    text-decoration: underline;
}

.App-link {
    color: #61dafb;
}

.ui.form .field > label {
    text-align: left;
}

.button {
    font-family: 'Kanit', sans-serif !important;
}

.banner1 {
    /*background-size: 100%;*/
    /*background-image: linear-gradient(#fff, #ccc);*/
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    color: #333;
    display: inline-block;
    font-size: 1rem;
    font-weight: 300;
    padding: 0.725rem 0.975rem;
    position: absolute;
    top: 50%;
    left: 50%;
    /*transform: translate(-50%, -50%);*/
    height: 100%;
    width: 100%;
}

.banner1:before {
    /*border-radius: inherit;*/
    /*background-image: linear-gradient(#ccc, #fff);*/
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -100;
    transition: opacity 0.45s ease-in-out;
}

.banner1:hover:before {
    opacity: 1;
}

.banner1:active:before {
    opacity: 0;
}

.link {
    color: #00aac6;
}

.multiply {
    font-size: 0.8rem;
}

.flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media all and (min-width: 480px) {
    .button {
        font-size: 1.25rem;
        padding: 1rem 1.5rem;
    }
}

@media (max-width: 767px) {
    /*Mobile*/
    .App-header a {
        /*margin-bottom: 1rem;*/
        line-height: 2;
    }

    .flex.row.wrap.padded > .padded {
        margin-top: 2rem;
    }

    .flex.row.wrap.padded > .padded:first-child {
        margin-top: 0;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ui.container.padded {
    padding: 2rem 0;
    text-align: left;
}

.ui.form {
    text-align: left;
}

.light-grey {
    background: #fafafa;
}

.SearchHeader {
    /*position: absolute !important;*/
    /*right: 0.5rem;*/
}

.cartItem {
    border-radius: 0.2rem;
    background: #FFF;
    padding: 1rem 1rem 1rem 1rem;
    margin-top: 1rem;
    line-height: 1.5;
}

.controlCartItem {
    line-height: 1;
    margin-top: 1rem;
}

.controlCartItem input {
    outline: none;
    border: 0;
    text-align: center;
}

.controlCartItem .icon {
    cursor: pointer;
}

.selectBank {
    padding: 0.5rem 0;
    border-radius: 0.5rem;
    cursor: pointer;
}

.selectBank.selected,
.selectBank:hover {
    background: #f4f4f4;
}

#root {
    /*height: 100%;*/
    /*overflow: auto;*/
}

.mainBackground {
    background: #282c34;
    color: #ffffffeb;
}

.secondBackground {
    position: relative;
    background-size: 100%;
    background-image: linear-gradient(#fff, #ccc);
}

.secondBackground.fluid {
    flex: auto;
}

.mainBorderRadius {
    border-radius: 0.5rem;
    padding: 1.5rem;
}

.ui.segment {
    padding: 1.8rem;
}

.colorGold {
    color: gold;
}

.orderList {
    background: #282c34 !important;
    margin-bottom: 3rem !important;
    padding: 0 !important;
    border-radius: 0.5rem;
    overflow: hidden;
}

.orderList .header {
    background: #ffffffe0;
    color: #000;
    padding: 0.4rem 1.2rem;
}

.orderList .content {
    padding: 1.2rem 1.2rem;
    background: #f7f7f7;
}

.orderList .fixLabel label {
    color: #ffffffc4 !important;
    font-weight: 100 !important;
}

.OrderItemList {
    margin: 0 0 1rem 0;
    padding: 1rem;
    background: #f0f0f0;
    border-radius: 0.3rem;
}

.indent {
    text-indent: 3rem;
}

img {
    max-width: 100%;
    height: auto;
}

.highlight2 {
    background-color: rgb(245, 245, 245);
    padding: 2rem;
    border-radius: 1.5rem;
    margin-bottom: 2rem;
}

.color.blue {
    color: #224577;
}
